<script>
import CommonLayout from "@/layouts/CommonLayout.vue";
import ImperativeDialog from "@/components/ImperativeDialog.vue";

import imageRaffleButton from "@/assets/images/raffle/raffle_button.png";
import imageRaffleButtonDisabled from "@/assets/images/raffle/raffle_button_disabled.png";
import imagePrize from "@/assets/images/raffle/prize.png";
import imageRulesBackground from "@/assets/images/raffle/rules_background.png";
import imageBackground from "@/assets/images/raffle_background.png";

import { apiGetLuckDay, apiLuckDraw, apiGetCheerNum } from "@/http";
import { notify } from "@/utils/notify";

export default {
  components: {
    CommonLayout,
    ImperativeDialog,
  },
  data() {
    return {
      imageRaffleButton,
      imageRaffleButtonDisabled,
      imagePrize,
      imageRulesBackground,
      imageBackground,
      isRafflePeriod: false,
      defers: [],
      prize: "",
      nums: {},
    };
  },
  methods: {
    getIsRafflePeriod() {
      apiGetLuckDay().then((res) => {
        this.isRafflePeriod = res.data.status;
      });
    },

    getNums() {
      return apiGetCheerNum().then((res) => {
        this.$set(this, "nums", res.data);
      });
    },

    navigateToPrizeHistory() {
      this.$router.push("/choujiang/me/history/prize");
    },

    handleRaffle() {
      if (!this.isRafflePeriod) {
        return;
      }

      this.getNums().then(() => {
        this.dialogPreRaffle?.open((context) => {
          context.close();
          apiLuckDraw().then((res) => {
            if (res.errorCode !== 1000) {
              return notify.error(res.errorMessage);
            }

            if (res.data === "-1") {
              this.dialogNoPrize?.open((context) => {
                context.close();
                this.navigateToPrizeHistory();
              });
            } else {
              this.prize = res.data;
              this.dialogPrize?.open((context) => {
                context.close();
                this.navigateToPrizeHistory();
              });
            }
          });
        })
      }) 
    },
  },
  computed: {
    dialogNoPrize() {
      return this.$refs.dialogNoPrize;
    },
    dialogPrize() {
      return this.$refs.dialogPrize;
    },
    dialogPreRaffle(){
      return this.$refs.dialogPreRaffle;
    }
  },
  beforeMount() {
    this.getIsRafflePeriod();
    this.getNums()
  },
  beforeDestroy() {
    this.defers.map((defer) => {
      defer();
    });
  },
};
</script>

<template>
  <CommonLayout
    title="共创杯.竞猜互动"
    color="light"
    :headerPlaceholder="false"
    transparentHeader
  >
    <div class="page">
      <div class="background">
        <img :src="imageBackground" class="background_image" />
      </div>
      <div class="body">
        <div class="slot">
          <div class="inner">
            <div class="prize">
              <img :src="imagePrize" />
            </div>
            <div class="raffle_button" @click="handleRaffle">
              <img v-if="isRafflePeriod" :src="imageRaffleButton" />
              <img v-else class="disabled" :src="imageRaffleButtonDisabled" />
            </div>
            <div class="rules">
              <img :src="imageRulesBackground" class="rules_background" />
              <div class="rules_content">
                <div class="title">参与方式</div>
                <div class="hightlight">100积分=1次抽奖</div>
                <div class="title">抽奖时间</div>
                <div class="hightlight">每周五 15:30-16:30</div>
                <div class="title">具体细则</div>
                <ol class="list">
                  <li>每次抽奖会消耗100积分,不限抽奖次数。</li>
                  <li>
                    积分可通过为助威领取，每人每天可助威4次，每次助威可领取10积分。
                  </li>
                  <li>中奖后，3个工作日内会有秘书MM拉群通知领取相关事宜。</li>
                  <li>奖品有限，抽完即止，祝大家好运连连！</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ImperativeDialog
      ref="dialogPreRaffle"
      confirmLabel="是"
      cancelLabel="否"
      stylish
    >
      <div style="height: 70px; display: flex; align-items: center; justify-content: center; padding-bottom: 16px; flex-direction: column;">
        是否使用100积分进行抽奖?
        <div style="font-size: 12px; opacity: .7; margin-top: 10px">
          您的积分余额为 <span style="color: #f67d53;">{{ nums.sign_integrate }}</span>
        </div>
      </div>
    </ImperativeDialog>
    <ImperativeDialog
      ref="dialogNoPrize"
      confirmLabel="中奖记录"
      cancelLabel="关闭"
      title="～再接再厉～"
      stylish
    >
      <div class="dialog no_prize_dialog">
        <div class="message">很遗憾没中奖</div>
        <div class="tips">下次再来试试吧</div>
      </div>
    </ImperativeDialog>
    <ImperativeDialog
      ref="dialogPrize"
      confirmLabel="中奖记录"
      cancelLabel="关闭"
      title="～恭喜中奖～"
      stylish
    >
      <div class="dialog prize_dialog">
        <div class="prize_name">{{ prize }}</div>
        <div class="tips">请留意后续秘书拉群发放</div>
      </div>
    </ImperativeDialog>
  </CommonLayout>
</template>

<style scoped lang="less">
@import "@/styles/design.less";

.page {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to bottom, #233176, #37335d);

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    .background_image {
      display: block;
      width: 100%;
    }
  }

  .body {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .slot {
      flex: 1 1;
      width: 100%;
      min-height: 180vw;

      .inner {
        padding-top: 90%;
      }
    }
  }
}

.prize {
  margin-top: 2vw;
  img {
    margin: auto;
    width: 95vw;
  }
}

.raffle_button {
  width: 100%;
  height: 25vw;
  position: relative;
  overflow: hidden;
  z-index: 10;

  img {
    width: 114%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;

    &.disabled {
      width: 101%;
      margin-top: -1vw;
    }
  }
}


.rules {
  width: 100%;
  margin: auto;
  margin-top: -12px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 88vw;

  .rules_background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    width: 94%;
    margin-left: 3%;
  }

  .rules_content {
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    font-family: Tencent;
    height: 70vw;
    overflow: auto;
    margin-top: 11vw;
    width: 90%;
    margin-left: 5%;
    padding: 0px 20px;

    .title {
      font-size: 14px;
      color: #ffffff;
      position: relative;
      width: 140px;
      margin: 10px auto;

      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 30px;
        height: 2px;
        border-radius: 2px;
        background-color: #ffffff;
        left: 0px;
        top: 50%;
        transform: translate(0, -50%);
      }

      &::after {
        left: auto;
        right: 0px;
      }
    }

    .hightlight {
      font-size: 14px;
      color: #fff04b;
    }

    .list {
      font-size: 12px;
      text-align: left;
      line-height: 1.4;
      list-style: decimal;
      padding-left: auto;
      color: #ffffff;
      // padding: 0px 60px;
    }
  }
}

.prize_dialog {
  .prize_name {
    font-size: 26px;
    color: #ff6a48;
    font-weight: 600;
  }

  .tips {
    font-size: 13px;
    color: #b1b1b1;
    margin-top: 16px;
  }
}

.no_prize_dialog {
  .message {
    font-size: 20px;
  }

  .tips {
    color: #b1b1b1;
    margin-top: 15px;
  }
}
</style>
